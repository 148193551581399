<template lang="pug">
  .pa-8.text-center(v-if="!profile")
    v-progress-circular(indeterminate, color="primary")
    .pa-2.caption
      span Loading profile...

  div(v-else)
    .d-block(v-if="$vuetify.breakpoint.smAndDown")
      .py-2
      Profile(:profile="profile", key="user-profile")
      .py-2
      v-tabs(v-model="tab1", fixed-tabs)
        v-tab(style="min-width: 40px"): v-icon mdi-heart
        v-tab(style="min-width: 40px"): v-icon mdi-music
        //- v-tab(style="min-width: 40px"): v-icon mdi-calendar-text
        //- v-tab(style="min-width: 40px"): v-icon mdi-podium-gold
        v-tab(style="min-width: 40px"): v-icon mdi-tag
      v-tabs-items(touchless, v-model="tab1")
        v-tab-item: Characters(:profile="profile", key="user-characters")
        v-tab-item: Musics(:profile="profile", key="user-musics")
        //- v-tab-item: Events(:profile="profile", :rankings="events", key="user-events")
        //- v-tab-item: RankMatches(:profile="profile", :rankings="rankMatches", key="user-rank-matches")
        v-tab-item: Honors(:profile="profile", key="user-honors")
      .py-2

    .d-flex(v-if="$vuetify.breakpoint.md")
      .split(style="width: calc(360px + (100% - 720px) / 4)")
        .py-2
        Profile(:profile="profile", key="user-profile")
        .py-2
        v-tabs(v-model="tab2", fixed-tabs)
          v-tab: v-icon mdi-heart
          //- v-tab: v-icon mdi-calendar-text
          //- v-tab: v-icon mdi-podium-gold
          v-tab: v-icon mdi-tag
        v-tabs-items(touchless, v-model="tab2")
          v-tab-item: Characters(:profile="profile", key="user-characters")
          //- v-tab-item: Events(:profile="profile", :rankings="events", key="user-events")
          //- v-tab-item: RankMatches(:profile="profile", :rankings="rankMatches", key="user-rank-matches")
          v-tab-item: Honors(:profile="profile", key="user-honors")
        .py-2

      v-divider(vertical)

      .split(style="width: calc(100% - 360px - 1px - (100% - 720px) / 4)")
        .py-2
        v-list-item(dense)
          v-list-item-title Musics
        Musics(:profile="profile", key="user-musics")
        .py-2
    
    .d-flex(v-if="$vuetify.breakpoint.lg")
      .split(style="width: 360px")
        .py-2
        Profile(:profile="profile", key="user-profile")
        .py-2
        v-list-item(dense)
          v-list-item-title Characters
        Characters(:profile="profile", key="user-characters")
        .py-2

      v-divider(vertical)

      .split(style="width: calc(100% - 720px - 2px - (100% - 1080px) / 4)")
        .py-2
        v-list-item(dense)
          v-list-item-title Musics
        Musics(:profile="profile", key="user-musics")
        .py-2

      v-divider(vertical)

      .split(style="width: calc(360px + (100% - 1080px) / 4)")
        .py-1
        v-tabs(v-model="tab3", fixed-tabs)
          v-tab: v-icon mdi-calendar-text
          v-tab: v-icon mdi-podium-gold
          v-tab: v-icon mdi-tag
        v-tabs-items(touchless, v-model="tab3")
          v-tab-item: Events(:profile="profile", :rankings="events", key="user-events")
          v-tab-item: RankMatches(:profile="profile", :rankings="rankMatches", key="user-rank-matches")
          v-tab-item: Honors(:profile="profile", key="user-honors")
        .py-2
    
    .d-flex(v-if="$vuetify.breakpoint.xl")
      .split(style="width: 360px")
        .py-2
        Profile(:profile="profile", key="user-profile")
        .py-2
        v-list-item(dense)
          v-list-item-title Characters
        Characters(:profile="profile", key="user-characters")
        .py-2

      v-divider(vertical)

      .split(style="width: calc(100% - 720px - 2px - (100% - 1800px) / 4)")
        //- .py-2
        //- v-list-item(dense)
        //-   v-list-item-title Musics
        Musics(:profile="profile", key="user-musics")
        //- .py-2

      v-divider(vertical)

      .split(style="width: calc(360px + (100% - 1800px) / 4)")
        .py-1
        v-tabs(v-model="tab3", fixed-tabs)
          v-tab: v-icon mdi-calendar-text
          v-tab: v-icon mdi-podium-gold
          v-tab: v-icon mdi-tag
        v-tabs-items(touchless, v-model="tab3")
          v-tab-item: Events(:profile="profile", :rankings="events", key="user-events")
          v-tab-item: RankMatches(:profile="profile", :rankings="rankMatches", key="user-rank-matches")
          v-tab-item: Honors(:profile="profile", key="user-honors")
        .py-2


</template>

<script>
import sekai from '@/sekai';
import { get, set } from 'idb-keyval';
import Divider from '@/components/Divider';
import Profile from './Profile';
import Musics from './Musics';
import Events from './Events';
import RankMatches from './RankMatches';
import Characters from './Characters';
import Honors from './Honors';

export default {
  name: 'User',

  props: ['id'],
  computed: {
    eventID() {
      var now = new Date;
      // if(!window.isJP)
      //   now.setFullYear(now.getFullYear()-1);
      return Object.values(this.$db.events).reduce((a, b) => {
        if (a.startAt > now) return b;
        if (b.startAt > now) return a;
        if (a.startAt > b.startAt) return a;
        return b;
      }).id;
    },
  },

  components: { Divider, Profile, Musics, Events, Characters, Honors, RankMatches },

  data() {
    let events = {};
    Object.values(this.$db.events).forEach(event => events[event.id] = null);
    let rankMatches = {};
    Object.values(this.$db.rankMatchSeasons).forEach(rankMatch => rankMatches[rankMatch.id] = null);
    return {
      profile: null,
      bonus: '',
      events: events,
      rankMatches: rankMatches,

      tab1: null,
      tab2: null,
      tab3: null,
    };
  },

  methods: {
    load() {
      let id = this.id;
      this.profile = null;
      Object.keys(this.events).forEach(i => this.events[i] = null);
      Object.keys(this.rankMatches).forEach(i => this.rankMatches[i] = null);

      sekai.api(`/api/user/${this.id}/profile`).then(response => {
        if (id != this.id) return;
        if (response.userDeck) { //jp patch
          response.userDecks = [response.userDeck]
          response.user.userGamedata = response.user
          response.userCard = response.userCards.find(e => e.cardId == response.userDeck.leader);
        } else {
          response.userDeck = response.userDecks[0]
          response.totalPower = {
            totalPower: ''
          };
        }
          const eventDeckBonuses = Object.values(this.$db.eventDeckBonuses);
          const eventCards = Object.values(this.$db.eventCards);
          const gameCharacterUnits = Object.values(this.$db.gameCharacterUnits);
          const selectedEventId = this.eventID;
          const eventRarityBonusRates = Object.values(this.$db.eventRarityBonusRates);
          console.log(eventRarityBonusRates);
          const eventBonuses = eventDeckBonuses
            .filter((edb) => edb.eventId === selectedEventId)
            .map((edb) => {
              if (edb.gameCharacterUnitId && edb.cardAttr) {
                const gameCharaUnit = gameCharacterUnits.find(
                  (gcu) => gcu.id === edb.gameCharacterUnitId
                );
                return {
                  bonusRate: edb.bonusRate,
                  cardAttr: edb.cardAttr,
                  gameCharacterId: gameCharaUnit.gameCharacterId,
                  unit: gameCharaUnit.unit,
                };
              } else if (edb.gameCharacterUnitId) {
                const gameCharaUnit = gameCharacterUnits.find(
                  (gcu) => gcu.id === edb.gameCharacterUnitId
                );
                return {
                  bonusRate: edb.bonusRate,
                  gameCharacterId: gameCharaUnit.gameCharacterId,
                  unit: gameCharaUnit.unit,
                };
              } else {
                return {
                  bonusRate: edb.bonusRate,
                  cardAttr: edb.cardAttr,
                };
              }
            });
          const eventCardBonuses = eventCards.filter(
            (ec) => ec.eventId === selectedEventId
          );
          let bonuses = Object.keys(response.userDeck).filter(e => e.includes('member')).reduce((sum,i) => {
            // let sum = 0;
            console.log(sum);
            let cardId = response.userDeck[i];
            let card = this.$db.cards[cardId];
            console.log(card, cardId);
            let teamCard = response.userCards.find(e => e.cardId == cardId);
            let bonus = eventBonuses.find(
              (eb) =>
                eb.gameCharacterId &&
                eb.cardAttr &&
                eb.unit &&
                eb.gameCharacterId === card.characterId &&
                eb.cardAttr === card.attr &&
                (card.characterId <= 20 || eb.unit === card.supportUnit)
            );
            if (!bonus) {
              bonus = eventBonuses.find(
                (eb) =>
                  (eb.gameCharacterId &&
                    eb.unit &&
                    !eb.cardAttr &&
                    eb.gameCharacterId === card.characterId &&
                    (card.characterId <= 20 || eb.unit === card.supportUnit)) ||
                  (eb.cardAttr &&
                    !eb.gameCharacterId &&
                    !eb.unit &&
                    eb.cardAttr === card.attr)
              );
            }
            const cardBonus = eventCardBonuses.find(
              (ecb) => ecb.cardId === card.id
            );
            let rarityBonus;
            if (eventRarityBonusRates)
              rarityBonus = eventRarityBonusRates.find(
                (erbr) =>
                  erbr.cardRarityType === card.cardRarityType &&
                  erbr.masterRank === teamCard.masterRank
              );
            
            console.log(card, teamCard,rarityBonus, 'cc');
            // if (!bonus) return sum;
            // else sum += bonus.bonusRate;
            if (bonus) sum += bonus.bonusRate;
            if (cardBonus && cardBonus.bonusRate) sum += cardBonus.bonusRate;
            if (
              !cardBonus &&
              card.characterId >= 21 &&
              card.supportUnit === "none"
            )
              sum += 15; // pure VS card bonus
            if (rarityBonus) sum += rarityBonus.bonusRate;
            return sum;

          },0)
          console.log(bonuses);
          response.totalPower.bonus = bonuses;
        
          get(this.id).then((val) => {
            if (val)
              set(this.id, response);
          });
        
        this.profile = response;
      }).then(() => {
        // Object.values(this.$db.events).filter(event => event.id >= this.$sekai.eventStartID).forEach(event => {
        //   sekai.api(`/api/user/{user_id}/event/${event.id}/ranking?targetUserId=${this.id}`).then(response => {
        //     if (id != this.id) return;
        //     this.events[event.id] = response.rankings && response.rankings[0] || {};
        //   });
        // });
        // Object.values(this.$db.rankMatchSeasons).forEach(rankMatch => {
        //   sekai.api(`/api/user/{user_id}/rank-match-season/${rankMatch.id}/ranking?targetUserId=${this.id}`).then(response => {
        //     if (id != this.id) return;
        //     this.rankMatches[rankMatch.id] = response.rankings && response.rankings[0] || {};
        //   });
        // });
      });
    }
  },

  mounted() {
    this.$nextTick(function () {
      this.load();
    });
  },

  watch: {
    id() {
      this.$nextTick(function () {
        this.load();
      });
    }
  }
};
</script>